import React, {ReactNode} from "react";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconClose from "@mui/icons-material/Close";
import IconMuted from "@mui/icons-material/VolumeOff";
import IconPlayForWork from "@mui/icons-material/PlayForWork";
import IconStop from "@mui/icons-material/Stop";
import List from "@mui/material/List";
import Slider from "@mui/material/Slider";
import {Avatar} from "@mui/material";
import {Divider} from "@mui/material";
import {IconButton} from "@mui/material";
import {ListItemButton} from "@mui/material";
import {ThemeProvider} from "@mui/material";
import {ListItemAvatar, Typography} from "@mui/material";
import {DeviceStatusIcons, IconStoreAndPlay, IconStream, RppColors} from "@barix/rpp-components";
import {ButtonTrigger, ChannelBase, Device, DeviceStatus, RemoteControlDataType, UUID} from "@barix/rpp-types";
import {DeviceCommonUtils} from "@barix/rpp-common";
import Controller from "./Controller";
import rppTheme from "./themes/RppTheme";
import {RemoteDeviceConfig} from "./remote.types";
import StreamMetadataUtils from "./StreamMetadataUtils";
import "./device-remote-control.css";


const ICON_SIZE = "16vmin";


export interface RemoteControlState {
    showVolume: boolean;
    // updateTime: number;
    volumeIntention: number;
    heightDiff: number;
    muteButtonLightState: boolean;
    buttonTriggerDrawerIsOpen: boolean;
    updatedTime: number;
    device: RemoteDeviceConfig | undefined;
    channels: ChannelBase[];
    buttonTriggers: ButtonTrigger[];
    excludedAssets: UUID[];
    isHeroPlanActive: boolean;
    permissions: {
        channelChangeEnabled: boolean;
        volumeChangeEnabled: boolean;
        cuePlayEnabled: boolean;
    }
    // orgButtonTriggerSet: ButtonTriggerSet | null;
}


class RemoteControl extends React.Component<{}, RemoteControlState> {


    private readonly headerRef: React.RefObject<HTMLTableElement>;
    private readonly bottomRef: React.RefObject<HTMLDivElement>;
    private _isMounted: boolean = false;
    private muteButtonTimer: NodeJS.Timeout | null = null;

    public constructor(props: {}, context: any) {
        super(props, context);
        this.state = {
            showVolume: false,
            volumeIntention: 0,
            heightDiff: 187,
            muteButtonLightState: true,
            buttonTriggerDrawerIsOpen: false,
            updatedTime: (new Date()).getTime(),
            device: undefined,
            channels: [],
            buttonTriggers: [],
            excludedAssets: [],
            isHeroPlanActive: false,
            permissions: {
                channelChangeEnabled: false,
                volumeChangeEnabled: false,
                cuePlayEnabled: false,
            },
        };

        this.headerRef = React.createRef();
        this.bottomRef = React.createRef();
        window.addEventListener("resize", this.handleResize);
    }


    public componentDidMount() {
        this._isMounted = true;
        const device = this.state.device;

        this.muteButtonTimer = setInterval(() => {
            if (this._isMounted) {
                if (device && device.muted) {
                    this.setState({muteButtonLightState: !this.state.muteButtonLightState});
                }
            }
        }, 500);
        setTimeout(this.handleResize, 500);
    }


    public componentWillUnmount() {
        if (this.muteButtonTimer) {
            clearInterval(this.muteButtonTimer);
        }
        this._isMounted = false;
    }


    onMessage = (type: string, payload: any) => {

        // console.log("Updated received...")
        // console.log("type:", type);
        // console.log("payload:", payload);

        let device = this.state.device;

        switch (type) {

            case "config":
                if (device) {
                    device = {...device, ...payload.device};
                } else {
                    device = payload.device;
                }

                // console.log("### DEVICE META -->", payload.device && payload.device.extra && payload.device.extra.streamMetadata && Array.isArray(payload.device.extra.streamMetadata) && payload.device.extra.streamMetadata.length > 0? JSON.stringify(payload.device.extra.streamMetadata[0]) : "NULLLLLL");
                // console.log("### DEVICE ACTIVE RESOURCE -->", payload.device && payload.device.status ? JSON.stringify(payload.device.status.activeResource) : "NULLLLLL");
                this.setState({
                    device,
                    channels: payload.channels,
                    buttonTriggers: payload.buttonTriggers,
                    excludedAssets: payload.excludedAssets,
                    isHeroPlanActive: payload.isHeroPlanActive,
                    volumeIntention: payload.device.volume,
                    permissions: payload.permissions,
                });
                break;

            case "update":

                const deviceDiff = payload.device;

                if (deviceDiff) {
                    if (deviceDiff.hasOwnProperty("volume") || deviceDiff.hasOwnProperty("channelList") ||
                        deviceDiff.hasOwnProperty("orgButtonTriggerSet") || deviceDiff.hasOwnProperty("online") ||
                        deviceDiff.hasOwnProperty("muted")
                    ) {
                        const device = {...this.state.device, ...deviceDiff};
                        const volumeIntention = payload.device.volume !== undefined ? payload.device.volume : device.volume;
                        this.setState({device, volumeIntention});
                        // console.log(`-----> beDiffMessageHandler data.volume: ${JSON.stringify(deviceDiff.volume)}`);
                    } else {
                        if (deviceDiff.hasOwnProperty("status")) {
                            const device = this.state.device;
                            if (device) {
                                device.status = deviceDiff.status;
                                this.setState({device});
                                // console.log(`-----> beDiffMessageHandler JUST Updating status`);
                            }
                        }
                    }
                }

                break;

            case "disconnected":
                this.setState({device: undefined});
                break;

            default:
                console.log(`Unknown WS message type "${type}"`);
        }

    }

    private controller = new Controller(this.onMessage);

    public render() {

        const device = this.state.device;

        return (
            <ThemeProvider theme={rppTheme}>
                <div style={{
                    color: "#CCCCCC",
                    fontFamily: "SourceSansPro, sans-serif",
                    fontWeight: "normal",
                    margin: "0",
                    height: "100%",
                    width: "100%",
                    userSelect: "none",
                    position: "fixed",
                    backgroundColor: "#2e2c2f",
                }}>
                    {}
                    {/*HEADER*/}
                    {this.renderHeader()}

                    {/*CHANNEL LIST*/}
                    {this.renderChannelList()}

                    {/*BOTTOM*/}
                    {this.renderBottom()}
                </div>

                <Drawer anchor="right"
                        open={this.state.buttonTriggerDrawerIsOpen}
                        onBackdropClick={(_event) => this.buttonTriggerDrawerClose()}
                        style={{fontFamily: "SourceSansPro, sans-serif"}}
                    // onClose={toggleDrawer(anchor, false)}
                >
                    {device && (
                        this.renderButtonTriggers(device)
                    )}
                </Drawer>

            </ThemeProvider>
        );
    }


    private handleResize = () => {
        const headerHeight = this.headerRef.current ? this.headerRef.current.clientHeight : 0;
        const bottomHeight = this.bottomRef.current ? this.bottomRef.current.clientHeight : 0;
        this.setState({heightDiff: headerHeight + bottomHeight});
    };


    private onPlay = (channel: ChannelBase) => {
        this.setChannel(channel);
    };


    private onVolumeChangeHandler = (event: Event, value: number | number[], _activeThumb: number) => {
        const val = Array.isArray(value) ? value.length ? value[0] : null : value;
        if (val) {
            this.setState({volumeIntention: val});
        }
    };


    private onVolumeCommitHandler = () => {
        this.setVolume(this.state.volumeIntention);
    };


    private buttonTriggerDrawerClose = () => {
        this.setState({buttonTriggerDrawerIsOpen: false});
    };


    private buttonTriggerDrawerToggle = () => {
        this.setState({buttonTriggerDrawerIsOpen: !this.state.buttonTriggerDrawerIsOpen});
    };


    private renderHeader(): ReactNode {

        const device = this.state.device;

        return (
            <table ref={this.headerRef} style={{
                backgroundColor: "#3C3A3D",
                borderBottom: "solid 1px #444444",
                padding: "1vmin 3vmin",
                display: "table",
                width: "100%",
                minHeight: "12vmin",
            }}>
                <tbody>
                <tr>
                    <td>
                        {RemoteControl.renderDeviceName(device ? device.name : "")}
                    </td>
                    <td style={{ width: "1%" }}>
                        <DeviceStatusIcons
                            style={{}}
                            device={device}
                            activeResourceI18N={device && device.status ? device.status.activeResource : ""}
                            onlineExpressionI18N={""}
                            statusLocalTimeLabelI18N={"Local time"}
                            iconsFontSize="4.5vmin"
                        />

                    </td>
                </tr>
                </tbody>
            </table>
        );
    }


    private renderChannelList(): ReactNode {

        const device = this.state.device;
        const channelsSorted = this.getChannels();

        return (
            <div style={{
                height: `calc(100% - ${this.state.heightDiff}px)`,
                overflowY: "auto",
                backgroundColor: "#2e2c2f",
            }}>

                {channelsSorted.length > 0 && (
                    <List>
                        <Divider/>
                        {channelsSorted.map((ch) => {
                            const channelIsSelected = RemoteControl.isChannelSelected(device, ch);
                            const itemColor = channelIsSelected ? "#CCCCCC" : "#7D7C7D";
                            const notSupportedByFirmware = device && device.status && !DeviceCommonUtils.deviceSupportsApiV4(device.status.firmwareVersion) && ch.type === "STORE_AND_PLAY";
                            return (
                                <ListItemButton
                                    key={"channel-" + ch.id}
                                    dense={true}
                                    divider
                                    autoFocus={channelIsSelected}
                                    onClick={(_event) => {
                                        if (this.state.permissions.channelChangeEnabled) {
                                            this.onPlay(ch);
                                        }
                                    }}
                                    style={{
                                        fontSize: "3.5vh",
                                        paddingLeft: "5vw",
                                        color: itemColor,
                                    }}
                                    disabled={notSupportedByFirmware || !this.state.permissions.channelChangeEnabled }
                                >
                                    <ListItemAvatar>
                                        <Avatar variant="square"
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: itemColor,
                                                }}>
                                            {ch.type === "STORE_AND_PLAY" ? <IconStoreAndPlay/> : <IconStream/>}
                                        </Avatar>
                                    </ListItemAvatar>
                                    {ch.name}{notSupportedByFirmware ?
                                    <div style={{fontSize: "2vh", margin: "1vh 0 0 1vw"}}>(not supported by
                                        firmware)</div> : null}
                                </ListItemButton>
                            );
                        })}
                    </List>
                )}

                {device && channelsSorted.length === 0 && device.channelsEnabled && (
                    RemoteControl.renderNoItemsWarnLabel("(No Channels configured)")
                )}

                {device && device.online && channelsSorted.length === 0 && !device.channelsEnabled && (
                    RemoteControl.renderNoItemsWarnLabel("(playing only tracks)")
                )}

            </div>
        );
    }

    private renderBottom(): ReactNode {

        const device = this.state.device;
        // const currentChannel = this.controller.getCurrentChannel();
        const channels = this.getChannels();

        let completeText = "";

        const currentMetadata = StreamMetadataUtils.extractLastMetadata(device as unknown as Device);
        if (device && device.status && device.status.activeResource) {

            switch (device.status.activeResource)    {

                case "EVENT":
                case "FAILOVER_TRACKS":
                case "LOCAL_USB":
                    completeText = device.status.activeStreamName ? device.status.activeStreamName : device.status.activeStream;
                    break;

                case "MAIN":
                case "FALLBACK":

                    let mdArtist = null;
                    let mdDate = null;
                    const playingResult = this.renderPlayingLabel(device as unknown as Device, channels, StreamMetadataUtils.getTitle(currentMetadata));
                    mdArtist = StreamMetadataUtils.getArtist(currentMetadata);
                    mdDate = StreamMetadataUtils.getDate(currentMetadata);
                    // Utils.aLog(this, "activePlayingLabel", playingResult.activePlayingLabel, ", mdArtist:",mdArtist, ", mdDate:", mdDate);
                    completeText = playingResult.activePlayingLabel + (mdArtist ? " | " + mdArtist + (mdDate && mdDate.length > 0 ? " | " + mdDate : "") : "");
                    break;

                case "PRIORITY":
                    completeText = device.status.priorityStream;
                    break;

                case "NONE":
                default:
            }
            // const playingResult = this.renderPlayingLabel(device as unknown as Device, channels, StreamMetadataUtils.getTitle(currentMetadata));
            // activePlayingLabel = playingResult.activePlayingLabel;
            // isChannelActive = playingResult.isChannelActive;
            // mdArtist = StreamMetadataUtils.getArtist(currentMetadata);
            // mdDate = StreamMetadataUtils.getDate(currentMetadata);
        }

        // const withTwoLines = mdArtist !== null && mdArtist.length > 0;

        const styleTransition = { transition: "all 0.25s, zoom 0s, width 0s, height 0s" };

        let scrollTime = "10s";
        let scrollAnimation = "text-scroll-animation-normal";

        if (completeText.length > 0)   {
            const bottomWidth = this.bottomRef.current ? this.bottomRef.current.clientWidth : 600;
            scrollTime = (bottomWidth/50) + "s";
            // Utils.aLog(this, "--- bottomWidth", bottomWidth);
            // Utils.aLog(this, "-------- ", scrollTime);
            if (completeText.length > 40)   {
                scrollAnimation = "text-scroll-animation-long";
                if (completeText.length > 40 )    {
                    scrollAnimation = "text-scroll-animation-very-long";
                }
            }
        }

        const dynamicStyles = {
            animation: `${scrollAnimation} ${scrollTime} linear infinite`,
        };
        // Utils.aLog(this, "STYLE", dynamicStyles);


        return (
            <div
                ref={this.bottomRef}
                style={{
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    backgroundColor: "#333134",
                    borderTop: "solid 1px #444444",
                    height: "36vmin",
                }}
            >
                <div style={styleTransition} id="scroll-text-container">
                    <div id="scroll-text"
                        style={{...RemoteControl.getActiveResourceLabelStyle(device && device.online ? device.status : null),
                            ...styleTransition,
                            ...dynamicStyles,
                    }}>
                        {completeText}
                    </div>
                </div>

                <div style={{borderBottom: "solid 1px #444444"}}/>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <IconButton
                            color="primary"
                            style={{
                                color: device && device.muted ? this.state.muteButtonLightState ? "#880000" : "#BB0000" : "",
                                transition: "color .2s ease-in",
                            }}
                            onClick={(_event) => this.toggleMute()}
                            disabled={!this.state.permissions.volumeChangeEnabled}
                        >
                            <IconMuted style={{width: ICON_SIZE, height: ICON_SIZE}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="caption" id="volume-slider" style={{color: "#888888", fontSize: "2vmin"}}>
                            volume
                        </Typography>
                        <Slider
                            style={{color: this.state.permissions.volumeChangeEnabled ? "#417CAF" : "unset", marginBottom: "8px"}}
                            value={this.state.volumeIntention}
                            min={0}
                            step={1}
                            max={100}
                            onChange={this.onVolumeChangeHandler}
                            onChangeCommitted={this.onVolumeCommitHandler}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => (<div
                                style={{fontSize: "5vmin", color: "#FFFFFF", fontWeight: "normal"}}>{value} </div>)}
                            aria-labelledby="volume-slider"
                            disabled={!this.state.permissions.volumeChangeEnabled}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            color="primary"
                            onClick={(_event) => this.buttonTriggerDrawerToggle()}
                            disabled={!this.state.permissions.cuePlayEnabled}
                        >
                            <IconPlayForWork style={{width: ICON_SIZE, height: ICON_SIZE}}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        );
    }


    /**
     * Sort Button Triggers
     * ### COPY ###
     */
    private buttonTriggersSort = (a: ButtonTrigger, b: ButtonTrigger) => {
        if (a.ordinal && b.ordinal && a.ordinal > b.ordinal) {
            return 1;
        } else {
            return -1;
        }
    };


    private renderPlayingLabel(device: Device, channels: ChannelBase[], streamMdTitle: string | null): { activePlayingLabel: string, isChannelActive: boolean } {

        let activePlayingLabel = "";
        let isChannelActive = false;

        if (device && device.online) {
            const currentChannel = channels.find((ch) => ch.id === (device ? device.channel : null));
            if (device.status && (device.status.activeResource === "FAILOVER_TRACKS" || device.status.activeResource === "EVENT")) {
                if (streamMdTitle &&  streamMdTitle.length>0)   {
                    activePlayingLabel = streamMdTitle;
                } else {
                    activePlayingLabel = device.status.activeStreamName ? device.status.activeStreamName : device.status.activeStream;
                }
            } else {
                if (currentChannel && currentChannel.name) {
                    if (streamMdTitle) {
                        activePlayingLabel = streamMdTitle;
                    } else {
                        activePlayingLabel = currentChannel.name;
                    }
                    isChannelActive = true;
                } else {
                    activePlayingLabel = "(no channel selected)";
                }
            }
        } else {
            activePlayingLabel = "(offline)";
        }
        return {activePlayingLabel, isChannelActive};
    }


    /**
     * Render button triggers panel with header
     * @param {RemoteDeviceConfig} deviceConfig
     * @return {ReactNode}
     * @private
     */
    private renderButtonTriggers(deviceConfig: RemoteDeviceConfig): ReactNode {

        const enabled = DeviceCommonUtils.canPlay(deviceConfig);
        const buttonTriggers = this.state.buttonTriggers;
        // const excludedAssets = this.controller.getExcludedAssets();
        const canUseHeroPlanFunctionalities = !this.isHeroPlanEnabled() || (this.isHeroPlanEnabled() && this.isHeroPlanOngoing());

        return (
            <div style={{backgroundColor: "#222222", color: "#CCCCCC", borderTop: "solid 1px #444444", height: "100%"}}>

                <div style={{display: "inline-block", float: "right", margin: "8px"}}>
                    <IconButton onClick={(_event) => {
                        this.buttonTriggerDrawerClose();
                    }}>
                        <IconClose style={{color: "#CCCCCC"}}/>
                    </IconButton>
                </div>

                <div style={{display: "inline-block", padding: "2vmin 0 0vmin 3vmin"}}>
                    <Typography variant="h6">
                        Cues
                    </Typography>
                    <Typography variant="caption">
                        Press a button to Play
                    </Typography>
                </div>

                <div style={{textAlign: "right", margin: "16px"}}>
                    <IconButton
                        size={"small"}
                        color={"default"}
                        onClick={() => this.stopPlay()}
                        title="Stop"
                        style={{backgroundColor: "#FFFFFF"}}
                    ><IconStop/>
                    </IconButton>
                </div>

                <div>
                    {canUseHeroPlanFunctionalities && buttonTriggers.length > 0 && (
                        <List style={{minWidth: "300px"}}>
                            <Divider/>
                            {buttonTriggers.sort((a, b) => this.buttonTriggersSort(a, b)).map((trigger) => {
                                // const excludedForDevice = trigger.asset ? excludedAssets.includes(trigger.asset) : false;
                                return (
                                    <ListItemButton key={"list-item-" + trigger.id}
                                              disabled={!enabled}
                                              onClick={(_event) => {
                                                  if (trigger.asset) {
                                                      this.buttonTrigger(trigger.id);
                                                  }
                                              }}
                                              style={{
                                                  color: trigger.properties.foregroundColor,
                                                  backgroundColor: trigger.properties.backgroundColor,
                                                  fontSize: "3.5vh",
                                              }}
                                    >
                                        {trigger.name}
                                        {/*{excludedForDevice && (*/}
                                        {/*    <ListItemSecondaryAction*/}
                                        {/*        key={"trigger-action-" + trigger.id}>*/}
                                        {/*        <IconBlock style={{color: "#00000044"}}/>*/}
                                        {/*    </ListItemSecondaryAction>*/}
                                        {/*)}*/}
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    )}

                    {canUseHeroPlanFunctionalities && buttonTriggers.length === 0 && (
                        RemoteControl.renderNoItemsWarnLabel("(No Triggers configured)")
                    )}

                    {!canUseHeroPlanFunctionalities && (
                        RemoteControl.renderNoItemsWarnLabel("Oops! This device has no RetailHero Plan")
                    )}

                </div>
            </div>
        );
    }


    private getCurrentChannel(): ChannelBase | null {
        if (!this.state.device) {
            return null;
        }
        const channel = this.state.device.channel;
        const [current] = this.state.channels.filter((ch) => ch.id === channel);
        return current;
    }


    private getChannels(): ChannelBase[] {
        if (this.state.device && this.state.device.channelsEnabled) {
            return this.state.channels;
        }
        return [];
    }

    private setVolume(volume: number) {
        if (volume >= 0 && volume <= 100) {
            if (this.state.device) {
                this.controller.send({type: RemoteControlDataType.set_volume, payload: {volume}});
                const device = this.state.device;
                device.volume = volume;
                this.setState({device});
            }
        }
    }

    private setChannel(channel: ChannelBase) {
        const channelId = channel.id;
        this.controller.send({type: RemoteControlDataType.set_channel, payload: {channel: channelId}});
        if (this.state.device) {
            const device = this.state.device;
            device.channel = channelId;
            this.setState({device});

        }
    }

    private toggleMute() {
        if (this.state.device) {
            const device = this.state.device;
            device.muted = !device.muted;
            this.setState({device});
            this.controller.send({type: RemoteControlDataType.set_mute, payload: {muted: this.state.device.muted}});
        }
    }

    private buttonTrigger(buttonTriggerId: UUID) {
        this.controller.send({type: RemoteControlDataType.BUTTON_TRIGGER, payload: {buttonTriggerId}});
    }

    private stopPlay(): void {
        this.controller.send({type: RemoteControlDataType.FLUSH_EVENTS, payload: {}});
    }


    private isHeroPlanOngoing(): boolean {
        return this.state.device !== undefined && (this.state.device.extra !== undefined ? this.state.device.extra.heroPlan.heroPlanStatus === "ongoing" : false);
    }

    public isHeroPlanEnabled(): boolean  {
        return this.state.isHeroPlanActive;
    }



    private static calculateFontSizeInVmin(text: string | null, marginVmin = 0): number {
        let fontSizeVmin = 8;
        if (text && text.length) {
            // FROM WebUI: fontSizeVmin = (160 - marginVmin) / text.length;
            fontSizeVmin = (200 - marginVmin) / text.length;
            if (fontSizeVmin > 9) {
                fontSizeVmin = 9;
            }
        }
        return fontSizeVmin;
    }


    private static getActiveResourceLabelStyle(deviceStatus: DeviceStatus | null): React.CSSProperties {

        let labelColor = "#FFFFFF";
        let labelFontStyle = "normal";

        if (deviceStatus && deviceStatus.activeResource) {
            switch (deviceStatus.activeResource) {

                case "MAIN":
                    labelColor = "#FFFFFF";
                    labelFontStyle = "normal";
                    break;

                case "FALLBACK":
                    labelColor = RppColors.deviceActiveResourceFallbackUrl();
                    labelFontStyle = "normal";
                    break;

                case "PRIORITY":
                    labelColor = RppColors.deviceActiveResourcePriority();
                    labelFontStyle = "normal";
                    break;

                case "LOCAL_USB":
                    labelColor = RppColors.deviceActiveResourceLocalUsb();
                    labelFontStyle = "normal";
                    break;

                case "FAILOVER_TRACKS":
                    labelColor = RppColors.deviceActiveResourceFailoverTrack();
                    labelFontStyle = "normal";
                    break;

                case "EVENT":
                    labelColor = RppColors.deviceActiveResourceEvent();
                    labelFontStyle = "normal";
                    break;

                default:
                    labelColor = "#7D7C7D";
                    labelFontStyle = "italic";
            }
        }


        return {
            textAlign: "center",
            color: labelColor,
            fontStyle: labelFontStyle,
            lineHeight: "15vmin",
            fontSize: RemoteControl.calculateFontSizeInVmin("0123456789") + "vmin",
            height: "15vmin",
        };

    }


    private static renderDeviceName(name: string): ReactNode {
        return (
            <div style={{
                display: "inline-block",
                // From WebUI: fontSize: RemoteControl.calculateFontSizeInVmin(name, 82) + "vmin",
                fontSize: RemoteControl.calculateFontSizeInVmin(name, 50) + "vmin",
                lineHeight: "13vmin",
            }}>
                {name}
                {/*({this.calculateFontSizeInVmin(name)})*/}
            </div>
        );
    }


    private static isChannelSelected(device: { channel: string } | undefined, channel: ChannelBase): boolean {
        return (device !== undefined && device.channel === channel.id);
    }


    private static renderNoItemsWarnLabel(text: string): ReactNode {
        return (
            <div style={{margin: "2vh 2vw", textAlign: "center", color: "#888888"}}>
                <Typography variant="body2" style={{fontStyle: "italic", fontSize: "2vmin"}}>
                    {text}
                </Typography>
            </div>
        );
    }

}


export default RemoteControl;
