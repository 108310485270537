import {ApiMetadataPayload, Device} from "@barix/rpp-types";


// TODO: MOVE THIS TO RPP-COMMON PACKAGE AFTER DEVELOP ITERATION

export default class StreamMetadataUtils {

    public static extractLastMetadata(device: Device | undefined): ApiMetadataPayload | null {
        if (device && device.extra && device.extra.streamMetadata && device.extra.streamMetadata &&
            Array.isArray(device.extra.streamMetadata) && device.extra.streamMetadata.length > 0 &&
            device.extra.streamMetadata[0]) {
            return device.extra.streamMetadata[0];
        }
        return null;
    }


    public static getTitle(streamMetadata: ApiMetadataPayload | null): string | null {
        if (streamMetadata && streamMetadata.metadata) {
            return streamMetadata.metadata.title;
        }
        return null;
    }


    public static getArtist(streamMetadata: ApiMetadataPayload | null): string | null {
        if (streamMetadata && streamMetadata.metadata) {
            return streamMetadata.metadata.artist;
        }
        return null;
    }


    public static getDate(streamMetadata: ApiMetadataPayload | null): string | null {
        if (streamMetadata && streamMetadata.metadata) {
            return streamMetadata.metadata.date;
        }
        return null;
    }


}
