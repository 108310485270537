import React from "react";
import {createRoot} from "react-dom/client";
import RemoteControl from "./RemoteControl";


// FOR REACT < 18
// ReactDOM.render(<RemoteControl />, document.getElementById("root"));


// FOR REACT 18
const rootElement = document.getElementById("root");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<RemoteControl/>);
}
