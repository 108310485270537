import {RemoteControlDataType} from "@barix/rpp-types";
import {RppConstants} from "./RppConstants";
import {RemoteData} from "./remote.types";


class Controller {


    private _ws: WebSocket | null = null;
    private socketCounter: number = 0;
    private changesSubscribed = false;
    private deviceId: string | null = null;


    public constructor(onMessage: (type: string, payload: any) => void) {
        this.onMessage = onMessage;
        this.connectWebSocket();
    }


    public getSocketCounter(): number {
        return this.socketCounter;
    }


    onMessage: (type: string, payload: any) => void;

    private connectWebSocket() {

        ++this.socketCounter;
        let host = window.location.host;

        // Hack to fix the port when run in debug mode.
        if (host.endsWith(":3001")) {
            host = host.replace(":3001", ":3030");
        }

        const remoteId = window.location.pathname
            .substring(1)
            .replace(/:/g, "-");
        const protocol = window.location.protocol === "https:" ? "wss" : "ws";
        const socketUrl = `${protocol}://${host}${RppConstants.REMOTE_CONTROL_WS_PREFIX}/${remoteId}`;
        console.log(`Connecting to`, socketUrl);
        this._ws = new WebSocket(
            socketUrl,
        );
        this._ws.onopen = this.onWsOpen;
        this._ws.onclose = this.onWsClose;
        this._ws.onerror = this.onWsError;
        this._ws.onmessage = this.onWsMessage;
    }


    private onWsOpen = () => {
        console.log("Connected to websocket");
    };


    private onWsClose = () => {
        this.changesSubscribed = false;
        if (this.onMessage) {
            this.onMessage("disconnected", {});
        }
        setTimeout(() => {
            this.connectWebSocket();
        }, 5000);
    };


    private onWsMessage = (ev: MessageEvent) => {
        try {
            const message: string = ev.data;
            const data: RemoteData = JSON.parse(message) as RemoteData;

            if (this.onMessage) {
                this.onMessage(data.type, data.payload);
            }

            this.deviceId = data.payload.device.id;

            if (this.deviceId) {

                // Only after first message
                if (!this.changesSubscribed) {
                    if (this._ws && this._ws.readyState === this._ws.OPEN) {
                        const subscribeDiffData = {
                            type: "subscribe",
                            payload: {subsId: "remote-control", topic: "device-diff", objId: this.deviceId},
                        };
                        this._ws.send(JSON.stringify(subscribeDiffData));
                        // console.log("Device changes subscribed...");

                        const subscribeChannelListDiff = {
                            type: "subscribe",
                            payload: {subsId: "remote-control", topic: "channelList-diff", objId: this.deviceId},
                        };
                        this._ws.send(JSON.stringify(subscribeChannelListDiff));
                        // console.log("ChannelList changes subscribed...");

                        const subscribeTriggersDiff = {
                            type: "subscribe",
                            payload: {subsId: "remote-control", topic: "triggerSet-diff", objId: this.deviceId},
                        };
                        this._ws.send(JSON.stringify(subscribeTriggersDiff));
                        // console.log("Trigger changes subscribed...");

                        this.changesSubscribed = true;
                    }
                }
            }
        } catch (err) {
            console.error("Error receiving ws message", err);
        }

    };


    private onWsError = (error: any) => {
        console.error("Websocket Error.", error);
        if (this._ws) {
            this._ws.close();
            this._ws = null;
        }
    };


    public send = (data: { type: RemoteControlDataType, payload: any }) => {
        if (this._ws && this._ws.readyState === this._ws.OPEN) {
            this._ws.send(JSON.stringify(data));
            // console.log("SENT", data);
        } else {
            console.error("Websocket not ready");
        }
    };

}


export default Controller;
