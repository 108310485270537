import createTheme from "@mui/material/styles/createTheme";


declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}


const rppTheme = createTheme({
    palette: {
        primary: {
            main: "#417CAF",
            // main: "#D32828",
        },
        secondary:  {
            // main: "#719ABE",
            main: "#D32828",
        }
    },
    typography: {
        "fontFamily": `"SourceSansPro", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 12,
        // "fontWeightLight": 200,
        // "fontWeightRegular": 300,
        // "fontWeightMedium": 400
    },
    components: {
        MuiTooltip: {
            styleOverrides:  {
                tooltip: {
                    fontSize: "1em",
                }
            }
        }
    }
});


export default rppTheme;